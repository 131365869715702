<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              class="none-bottom" 
              type="search" 
              name="plantCd" 
              v-model="searchParam.plantCd"  />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :range="true"
              label="기간"
              type="month"
              name="period"
              defaultStart="-12M"
              defaultEnd="-1M"
              v-model="period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="padding-top:15px !important;">
            <c-btn label="검색" icon="search" @btnClicked="plantChange" />
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="yearOcuurChart" 
            height="350" 
            type="bar"
            :options="yearOcuurChart.chartOptions" 
            :series="yearOcuurChart.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="seasonOcuurChart" 
            height="350" 
            type="bar"
            :options="seasonOcuurChart.chartOptions" 
            :series="seasonOcuurChart.series"></apexchart>
        </div>
      </div>
      <div class="col-12">
        <apexchart 
          class="accidentChart"
          ref="hourOcuurChart" 
          height="350" 
          type="line"
          :options="hourOcuurChart.chartOptions" 
          :series="hourOcuurChart.series"></apexchart>
      </div>
      <div class="row">
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="kindOcuurChart1" 
            height="420" 
            type="bar"
            :options="kindOcuurChart1.chartOptions" 
            :series="kindOcuurChart1.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="kindOcuurChart2" 
            height="420" 
            :options="kindOcuurChart2.chartOptions" 
            :series="kindOcuurChart2.series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'accident-status',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
      searchParam: {
        plantCd: '',
        accidentTypeCd: null,
      },
      yearOcuurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '년도별 공정사고 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '80%',
      },
      hourOcuurChart: {
        chartOptions: {
          title: {
            text: '시간대별 공정사고 발생 건수'
          },
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          xaxis: {
            categories: ['00시', '01시', '02시', '03시', '04시', '05시', '06시', '07시', '08시','09시','10시','11시','12시',
            '13시','14시','15시','16시','17시','18시','19시','20시','21시','22시','23시'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [
        ],
        chartWidth: '80%',
      },
      seasonOcuurChart: {
        chartOptions: {
          title: {
            text: '계절별 공정사고 발생 건수'
          },
          colors: this.colors,
          labels: {
            style: {
              colors: this.colors,
              fontSize: '12px'
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [ '봄', '여름', '가을', '겨울'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '80%',
      },
      kindOcuurChart1: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '사고 유형별 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            position: 'top',
            horizontalAlign: 'center',
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      kindOcuurChart2: {
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
          },
          title: {
            text: '발생원인별 발생 건수'
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
        series: [],
      },
      period: [],
      options: [],
      selectProcessCd: '',
      editable: true,
      searchUrl: '',
      count: 0,
      yearUrl: '',
      hourUrl: '',
      seasonUrl: '',
      kindUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.yearUrl = selectConfig.sop.iim.accident.status.year.url;
      this.hourUrl = selectConfig.sop.iim.accident.status.hour.url;
      this.seasonUrl = selectConfig.sop.iim.accident.status.season.url;
      this.kindUrl1 = selectConfig.sop.iim.accident.status.kind1.url;
      this.kindUrl2 = selectConfig.sop.iim.accident.status.kind2.url;
      // 년도 설정
      // let years = [];
      // for (let i = 0; i < 5; i++) {
      //   years.splice(0, 0, this.$comm.getPrevYear(i));
      // }
      // this.yearOcuurChart.chartOptions.xaxis.categories = years;

      this.period = [this.$comm.getPrevYear(2), this.$comm.getThisYear()];
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];

      // url setting
      this.getYearList();
      this.getHourList();
      this.getSeasonList();
      this.getKindList1();
      this.getKindList2();

      this.setChartLabel();
    },
    setChartLabel() {
      this.yearOcuurChart.chartOptions.title.text = this.$language('년도별 공정사고 발생 건수') 
      this.hourOcuurChart.chartOptions.title.text = this.$language('시간대별 공정사고 발생 건수') 
      this.seasonOcuurChart.chartOptions.title.text = this.$language('계절별 공정사고 발생 건수') 
      this.seasonOcuurChart.chartOptions.xaxis.categories = [
        this.$language('봄'), // 봄
        this.$language('여름'), // 여름
        this.$language('가을'), // 가을 
        this.$language('겨울'), // 겨울
      ]

      this.kindOcuurChart1.chartOptions.title.text = this.$language('사고 유형별 발생 건수')
      this.kindOcuurChart2.chartOptions.title.text = this.$language('발생원인별 발생 건수') 

      this.$refs['yearOcuurChart'].refresh();
      this.$refs['hourOcuurChart'].refresh();
      this.$refs['seasonOcuurChart'].refresh();
      this.$refs['kindOcuurChart1'].refresh();
      this.$refs['kindOcuurChart2'].refresh();
    },
    getYearList() {
      this.$http.url = this.yearUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.yearOcuurChart.series = [];
        this.yearOcuurChart.chartOptions.xaxis.categories = [];
        // this.$_.forEach(_result.data, _item => {
        //   this.yearOcuurChart.chartOptions.xaxis.categories.push(_item.occurYear)
        // })
        this.$_.forEach(_result.data, _item => {
          // 년도에 따른 idx를 구하여 series.data의 위치 통일
          if (this.yearOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurYear) === -1) {
            this.yearOcuurChart.chartOptions.xaxis.categories.push(_item.occurYear);
          }

          let idx = this.yearOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurYear)
          if (this.$_.findIndex(this.yearOcuurChart.series, { plantCd: _item.plantCd}) === -1) {
            let _data = [];
            this.$_.forEach(this.yearOcuurChart.chartOptions.xaxis.categories, _item => {
              _data.push(0)
            })

            this.yearOcuurChart.series.push({
              plantCd: _item.plantCd,
              name: _item.plantName,
              data: _data
            })
          }
          this.yearOcuurChart.series[this.$_.findIndex(this.yearOcuurChart.series, 
          { plantCd: _item.plantCd })].data[idx] = _item.yearCount
        })
        this.$refs['yearOcuurChart'].refresh();
        this.yearOcuurChart.chartWidth = '90%';
      },);
    },
    getHourList() {
      this.$http.url = this.hourUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.hourOcuurChart.series = [];

        this.$_.forEach(_result.data, _item => {
        // 년도에 따른 idx를 구하여 series.data의 위치 통일
          let idx = this.hourOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurTime)
          if (this.$_.findIndex(this.hourOcuurChart.series, { plantCd: _item.plantCd}) === -1) {
            this.hourOcuurChart.series.push({
              plantCd: _item.plantCd,
              name: _item.plantName,
              //24개
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            })
          }

          this.hourOcuurChart.series[this.$_.findIndex(this.hourOcuurChart.series, 
          { plantCd: _item.plantCd })].data[idx] = _item.timeCount
        })
        this.hourOcuurChart.chartWidth = '90%';
        this.$refs['hourOcuurChart'].refresh();
      },);
    },
    getSeasonList() {
      this.$http.url = this.seasonUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.seasonOcuurChart.series = [];

        this.$_.forEach(_result.data, _item => {
        // 년도에 따른 idx를 구하여 series.data의 위치 통일
          let idx = 0
          if (_item.occurMonth === '12' || _item.occurMonth === '01' || _item.occurMonth === '02') {
            idx = 3;
          } else if (_item.occurMonth === '03' || _item.occurMonth === '04' || _item.occurMonth === '05') {
            idx = 0;
          } else if (_item.occurMonth === '06' || _item.occurMonth === '07' || _item.occurMonth === '08') {
            idx = 1;
          } else if (_item.occurMonth === '09' || _item.occurMonth === '10' || _item.occurMonth === '11') {
            idx = 2;
          }

          if (this.$_.findIndex(this.seasonOcuurChart.series, { plantCd: _item.plantCd}) === -1) {
            this.seasonOcuurChart.series.push({
              plantCd: _item.plantCd,
              name: _item.plantName,
              data: [0, 0, 0, 0]
            })
          }
          this.seasonOcuurChart.series[this.$_.findIndex(this.seasonOcuurChart.series, 
          { plantCd: _item.plantCd })].data[idx] += Number(_item.monthCount)
        })

        this.$refs['seasonOcuurChart'].refresh();
        this.seasonOcuurChart.chartWidth = '90%';
      },);
    },
    getKindList1() {
      this.$http.url = this.kindUrl1;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.kindOcuurChart1.series = [];
        this.kindOcuurChart1.chartOptions.xaxis.categories = [];
        let _data = [];
        this.$_.forEach(_result.data, _item => {
          this.$_.forEach(_item.accidentKindName.split(','), __item => {
            let idx = this.kindOcuurChart1.chartOptions.xaxis.categories.indexOf(__item)
            if (idx === -1) {
              this.kindOcuurChart1.chartOptions.xaxis.categories.push(__item)
              _data.push(Number(_item.kindCount))
            } else {
              _data[idx] = Number(_data[idx]) + Number(_item.kindCount);
            }
          })
        })
        this.kindOcuurChart1.series = [{data: _data, name: this.$language('발생 건수')}] 
        this.$refs['kindOcuurChart1'].refresh();
        this.kindOcuurChart1.chartWidth = '90%';
      },);
    },
    getKindList2() {
      this.$http.url = this.kindUrl2;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.kindOcuurChart2.series = [];
      this.kindOcuurChart2.chartOptions.labels = [];
      this.$http.request((_result) => {
        let _data = [];
        this.$_.forEach(_result.data, _item => {
          this.kindOcuurChart2.chartOptions.labels.push(_item.occurCauseName)
          _data.push(Number(_item.occurCauseCount))
        })
        this.kindOcuurChart2.series = _data

        this.$nextTick(function () {
          this.$refs['kindOcuurChart2'].refresh();
          this.kindOcuurChart2.chartWidth = '90%';
        })
      },);
    },
    plantChange() {
      if(this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      if (this.searchParam.plantCd.split(',').length > 1) {
        this.yearOcuurChart.chartOptions.plotOptions.bar.distributed = false
        this.seasonOcuurChart.chartOptions.plotOptions.bar.distributed = false
      } else {
        this.yearOcuurChart.chartOptions.plotOptions.bar.distributed = true
        this.seasonOcuurChart.chartOptions.plotOptions.bar.distributed = true
      }
      this.getYearList();
      this.getHourList();
      this.getSeasonList();
      this.getKindList1();
      this.getKindList2();
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important

// .el-cascader-node
//   padding: 0 30px 0 5px !important
// .el-cascader-menu
//   min-width: auto
// .el-cascader-node__label
//   padding: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  // border-right: #dee2e6 solid 0 !important
  // border-bottom: #dee2e6 solid 1px !important
  padding: 0px !important
.widget-yearOcuurChart
  padding: 5px !important
.widget-yearOcuurChart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important

.accidentChart
  padding: 20px !important
  border: 1px solid #dee2e6
  border-top: 3px solid #dee2e6
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>